/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import 'assets/scss/style.scss';
@import 'assets/scss/responsive.scss';

// OWL
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
body {
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
}

.ngx-pagination{
    .current{
        padding: 3px 12px !important;
        border-radius: 2px !important;
        background-color: #1a95bd !important;
    }
}

.pac-container{
    z-index: 9999;
}