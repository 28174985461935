$primary-blue: #1a95bd;
$black-color: #2E2E2E;
$white-color: #fff;
$light-blue-color: #6adaff;
$dark-black: #000;

.container-xxl {
    max-width: 1420px;
}
.owl-carousel{
    .owl-item{
        img{
            display: unset !important;
        }
    }
}

.z-index {
    z-index: 999;
}

.space-nav {
    margin-top: 138px;
}

.custom-primary-btn {
    border: 1px solid $primary-blue;
    background-color: $primary-blue;
    color: $white-color;
    border-radius: 10px !important;
    // height: 50px;

    &:hover,
    &:active {
        background-color: $light-blue-color !important;
        border: 1px solid $light-blue-color !important;
        color: $white-color !important;
    }

    &.rounded {
        border-radius: 40px !important;
    }

    &.outline{
        background-color: $white-color;
        color: $primary-blue;
    }

    &:disabled {
        color: $white-color;
        border: none;
        background-color: rgba($primary-blue, 0.5);
    }
}

.search-box, .search-place{
    .dropdown-menu{
        width: 90% !important;
        .dropdown-item{
            overflow: hidden !important;
        }
    }
}

.invalid {
    border-color: #A70000;
    box-shadow: 0 0 0 0.25rem rgba($color: #A70000, $alpha: .4);
}

.modal-btn-close {
    position: absolute;
    right: 15px;
    top: 15px;

    &:focus {
        box-shadow: none;
    }
}

.login-modal {
    .title {
        margin-top: 20px;
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 54px;
        color: $primary-blue;
        margin-bottom: 5px;
        @media screen and (max-width: 480px){
            font-size: 20px;
            line-height: 30px;
        }
    }

    .sub-title {
        font-style: normal;
        // font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #2E2E2E;
        margin-bottom: 10px;
        @media screen and (max-width: 480px){
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
            margin-bottom: 0px;
        }
    }

    .form-label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #2E2E2E;
        @media screen and (max-width: 480px){
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
        }
    }

    .phone-field-wrapper {
        border: 1px solid $primary-blue;
        border-radius: 10px;
        height: 55px;
        width: 100%;
        padding: 0 10px;
        @media screen and (max-width: 480px){
            height: 35px;
            border-radius: 5px;
        }

        .country-code {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #2E2E2E;
            margin-bottom: 0;
            margin-left: 5px;
            @media screen and (max-width: 480px){
                margin-top: 6px;
                font-size: 15px;
                line-height: 15px;
            }
        }

        .number-field {
            margin-left: 10px;
            width: 100%;
            border: none;
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            color: #838383;
            @media screen and (max-width: 480px){
                font-size: 15px;
                height: 32px;
            }

            &:focus {
                outline: none;
            }
        }

        .edit-number {
            padding: 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $primary-blue;
            width: 120px;
        }
    }

    .privacy-policy {
        .form-check {
            .form-check-input {
                width: 14px;
                height: 14px;
            }

            .form-check-label {
                @media screen and (max-width: 480px){
                    font-size: 9px;
                }
                position: relative;
                bottom: 3px;
                font-weight: 400;
                font-size: 12px;
                line-height: 21px;
                color: #2E2E2E;
            }
        }

        .page-link {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: $primary-blue;
            @media screen and (max-width: 480px){
                margin-top: 0px !important;
                font-size: 9px;
            }
        }
    }

    .ng-otp-input-wrapper {
        .otp-input {
            width: 59.59px;
            height: 54.38px;
            border: 1.5px solid $primary-blue;
            border-radius: 5px;
            font-size: 30px;
            text-align: center;
            color: $primary-blue;
            margin-right: 10px;
            @media screen and (max-width: 480px){
                height: 24px;
                width: 24px;
                font-size: 16px;
            }

            &:focus {
                outline: none;
            }
        }
    }

    .timer {
        text-align: center;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #2E2E2E;

        .timer-span {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #2E2E2E;
        }
    }
}

.form-check-input {
    &:focus {
        box-shadow: none;
    }
}

h1,
.h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
}

.sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.upper-header {
    background: $white-color;
    padding: 5px;

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.font14 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.txt-black {
    color: $black-color;
}

.blue-txt {
    color: $primary-blue;
    font-size: 14px;
}

.lang-select {
    display: inline-block;
}

.find-a-store {
    display: inline-block;
}

.lang-select select {
    border: none;
}

.lang-select {
    margin-right: 20px;
}

.lang-select select:focus {
    border: none;
}

.news-letter-input-box .subs-btn:hover {
    background: $dark-black;
}

.login-btn {
    border-color: $primary-blue;
    background-color: $white-color;

    &:hover {
        background: $light-blue-color !important;
    }
}

.shadow2 {
    box-shadow: 3px 3px 3px rgb(68 68 68 / 26%);
}

.pull-right {
    float: right;
}

.custom-navbar {
    background: #F5F5F5;
}

.custom-navbar{
    .add-to-cart-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1.5px solid $primary-blue;
        border-radius: 68px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: $primary-blue !important;
        width: 150px;
        text-align: center;
    
        img{
            vertical-align: sub;
            padding-left: 4px;
        }
    
        &:hover {
            border-color: $primary-blue;
        }
    
        @media screen and (max-width: 480px){
            padding: 0;
            font-style: normal;
            font-weight: 700;
            font-size: 8px;
            line-height: 12px;
            width: 105px;
            height: 25px;
        }
    }
}

.search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-radius: 65px;
    border: 1px solid #ced4da;
    background-color: $white-color;
    padding: 0 15px;
    flex-shrink: 0;
    // flex-grow: 1;
    // max-width: 700px;
    // min-width: 250px;
    height: 40px;

    .searchinput {
        border: none;
        font-weight: 400;
        font-size: 14px;
        color: $black-color;
        height: 38px;
        width: 100%;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .fa-search {
        margin-left: 10px;
        font-size: 13px;
    }
}

.search-box .nav-link {
    padding: 0;
}

.login-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $primary-blue;
    border-radius: 68px;
    color: $white-color !important;
    font-size: 14px;
    width: 100px;
    text-align: center;
    @media screen and (max-width: 480px){
        font-style: normal;
        font-weight: 700;
        font-size: 8px;
        line-height: 12px;
        height: 25px;
    }
}

.custom-navbar {
    .navbar-nav {
        .nav-item {
            margin-right: 20px;
        }
    }
}

.location {
    width: 200px;
}

// .custom-navbar .nav-item:last-child {margin-right: 0;}
.noti-icon {
    position: relative;

    span {
        position: absolute;
        top: 0px;
        right: 0px;
        background: #f32054;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: $white-color;
        text-align: center;
        font-size: 13px;
        z-index: 2;
    }

    img {
        width: 22px;
        position: relative;
        z-index: 0;
    }
}

/* header ends  */

/* banner slider start  */
.slider-txt {
    font-size: 28px;
    text-transform: capitalize;
    color: $primary-blue;
    font-weight: 700;
}

.slider-order-btn {
    background: $primary-blue;
    font-size: 16px;
    color: $primary-blue;
    padding: 10px 20px;
    text-transform: capitalize;
    font-weight: 600;
    border-radius: 5px;
}

.bannerslider {
    max-height: 700px;
    overflow: hidden;
    .banner {
        height: 100%;
        width: 100%;
        max-height: 400px;
        // max-height: 530px;
        object-fit: cover;
    }
}

.bannerslider .carousel-caption {
    bottom: 15%;
    right: 5%;
    left: auto;
}

.bannerslider .carousel-indicators button {
    background-color: $primary-blue;
}

.slider-txt {
    margin-bottom: 24px;
}

.blob-btn {
    z-index: 1;
    position: relative;
    outline: none;
    border: none;
    transition: color 0.5s;
    cursor: pointer;
    border-radius: 5px;
}

.blob-btn:before {
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 2px solid $primary-blue;
    border-radius: 5px;
}

.blob-btn:after {
    content: "";
    z-index: -2;
    position: absolute;
    left: 3px;
    top: 3px;
    width: 100%;
    height: 100%;
    transition: all 0.3s 0.2s;
    border-radius: 5px;
}

.blob-btn:hover {
    color: $white-color;
    border-radius: 5px;
}

.blob-btn:hover:after {
    transition: all 0.3s;
    left: 0;
    top: 0;
    border-radius: 5px;
}

.blob-btn__inner {
    z-index: -1;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: $white-color;
}

.blob-btn__blobs {
    position: relative;
    display: block;
    height: 100%;
    filter: url('#goo');
}

.blob-btn__blob {
    position: absolute;
    top: 2px;
    width: 25%;
    height: 100%;
    background: $primary-blue;
    border-radius: 100%;
    transform: translate3d(0, 150%, 0) scale(1.7);
    transition: transform 0.45s;
}

.blob-btn__blob:nth-child(1) {
    left: 0%;
    transition-delay: 0s;
}

.blob-btn__blob:nth-child(2) {
    left: 30%;
    transition-delay: 0.08s;
}

.blob-btn__blob:nth-child(3) {
    left: 60%;
    transition-delay: 0.16s;
}

.blob-btn__blob:nth-child(4) {
    left: 90%;
    transition-delay: 0.24s;
}

.blob-btn:hover .blob-btn__blob {
    transform: translateZ(0) scale(1.7);
}

/* banner slider ends  */

/* Shop By Categories start  */


.common-pd {
    padding: 50px 0;
}

.white-bg {
    background: $white-color;
}

.main-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: $black-color;
}

.sub-title-width80 {
    width: 40%;
    margin: 0 auto;
}

.categories-list ul {
    list-style-type: none;
    padding: 0;
}

.categories-list ul li {
    display: inline-block;
    width: 100%;
    margin: 10px;
}

.categories-list .owl-carousel .owl-item {
    margin-top: 30px;
}

.cat-offer-txt-bg {
    background: #444;
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    height: 40px;
    width: 170px;
    border-radius: 0 0 180px 180px;
    background-color: rgb(26 149 189 / 57%);
    overflow: hidden;
    line-height: 35px;
}

.category-img:hover {
    border: 3px solid #4083a4;
    padding: 5px;
    transition: all 0.2s ease;
}

.cat-offer-txt {
    color: $white-color;
    font-size: 13px;
    font-weight: 600;
    overflow: hidden;
}

.category-img {
    height: 200px;
    width: 200px;
    margin-bottom: 14px !important;
    border-radius: 50% !important;
    margin: 0 auto;
    display: table;
    overflow: hidden;
    position: relative;
    transition: all 0.5s ease;
}

.category-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.font16-bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: $black-color;
}

.categories-list {
    text-align: center;
    margin-top: 30px;
}

.text-hover-blue:hover {
    color: $primary-blue;
}

/* animation start  */


.hovicon {
    position: relative;
    z-index: 1;
}

.hovicon:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.hovicon:before {
    speak: none;
    font-size: 48px;
    line-height: 90px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    display: block;
    -webkit-font-smoothing: antialiased;
}

.hovicon.effect-1 {
    background: rgba(255, 255, 255, 0.1);
    -webkit-transition: background 0.2s, color 0.2s;
    -moz-transition: background 0.2s, color 0.2s;
    transition: background 0.2s, color 0.2s;
}

.hovicon.effect-1:after {
    top: -7px;
    left: -7px;
    padding: 7px;
    box-shadow: 0 0 0 4px $primary-blue;
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    -webkit-transform: scale(.8);
    -moz-transition: -moz-transform 0.2s, opacity 0.2s;
    -moz-transform: scale(.8);
    -ms-transform: scale(.8);
    transition: transform 0.2s, opacity 0.2s;
    transform: scale(.8);
    opacity: 0;
}

@-webkit-keyframes spinAround {
    from {
        -webkit-transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes spinAround {
    from {
        -moz-transform: rotate(0deg)
    }

    to {
        -moz-transform: rotate(360deg);
    }
}

@keyframes spinAround {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg);
    }
}

/* animation ends  */
/* Shop By Categories ends  */

/* banner 2 start  */

.banner2 {
    position: relative;
}

.banner2 img {
    width: 100%;
}

.banner2-content {
    position: absolute;
    top: 10%;
    left: auto;
    color: $white-color;
    width: 60%;
    padding: 0 3%;
}

.banner2-content h1 {
    text-transform: capitalize;
    color: $white-color;
    font-weight: 700;
    font-size: 40px;
}

.banner2-content p {
    font-weight: 600;
    font-size: 24px;
    width: 60%;
    margin-top: 20px;
}

/* banner 2 ends  */

/* footer start  */
/* footer social icons */
ul.social-network {
    list-style: none;
    display: inline;
    margin-left: 0 !important;
    padding: 0;
}

ul.social-network li {
    display: inline;
    margin: 0 5px;
}


.social-network a.icoRss:hover {
    background-color: #F56505;
}

.social-network a.icoFacebook:hover {
    background-color: #3B5998;
}

.social-network a.icoTwitter:hover {
    background-color: #33ccff;
}

.social-network a.icoGoogle:hover {
    background-color: #BD3518;
}

.social-network a.icoVimeo:hover {
    background-color: #0590B8;
}

.social-network a.icoLinkedin:hover {
    background-color: #007bb7;
}

.social-network a.icoRss:hover i,
.social-network a.icoFacebook:hover i,
.social-network a.icoTwitter:hover i,
.social-network a.icoGoogle:hover i,
.social-network a.icoVimeo:hover i,
.social-network a.icoLinkedin:hover i {
    color: $white-color;
}

a.socialIcon:hover,
.socialHoverClass {
    color: #44BCDD;
}

.social-circle li a {
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    width: 40px;
    height: 40px;
    font-size: 20px;
}

.social-circle li i {
    margin: 0;
    line-height: 40px;
    text-align: center;
}

.social-circle li a:hover i,
.triggeredHover {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms--transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
}

.social-circle i {
    color: #3097b9;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}

.social-circle a {
    background-color: $white-color;
}


.footer-cls {
    background: linear-gradient(180deg, #0E556C 0%, $primary-blue 100%);
    opacity: 0.9;
    color: $white-color;
    padding: 50px 0;
    .payment-cards{
        img{
            height: 42px;
            width: 60px;
        }
    }
}

.font28-bold {
    font-weight: 700;
    font-size: 28px;
    // color: $white-color;
}

.ft-logo {
    width: 54px;
    height: 54px;
    margin-bottom: 20px;
}

.footer-cls hr {
    margin-top: 40px;
    margin-bottom: 40px;
    border-top: 1px solid $white-color !important;
}

.news-letter-input-box {
    position: relative;
}

.news-letter-input-box input {
    width: 70%;
    height: 50px;
    display: inline-block;
    border-radius: 5px 0 0 5px;
    border: none;
}

.news-letter-input-box .subs-btn {
    width: 30%;
    display: inline-block;
    float: right;
    height: 50px;
    border-radius: 0 5px 5px 0;
    border: none;
    background: $primary-blue;
    color: $white-color;
    text-transform: capitalize;
    font-size: 16px;
}

.ft-links-title {
    font-weight: 600;
    font-size: 16px;
    color: $white-color;
}

.footer-links-list {
    padding: 0;
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 30px;
}

.footer-links-list li {
    margin-bottom: 10px;
    text-transform: capitalize;
    -webkit-transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    transition: all 300ms linear 0s;
    position: relative;
    padding-left: 20px;
}

.footer-links-list li a {
    font-weight: 400;
    font-size: 14px;
    color: $white-color;
}

.footer-links-list li .fa {
    padding-right: 10px;
}

.footer-product-list {
    padding: 0;
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 40px;
}

.footer-product-list li {
    display: inline-block;
    margin: 5px;
    width: 28%;
}

.ft-product-img img {
    border-radius: 5px;
}

.copy-right-footer {
    padding: 10px;
    color: $white-color;
    background: #003B4F;
}

.copy-right-footer p {
    margin-bottom: 0;
}

.font16 {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

.footer-links-list li .fa-caret-right {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    transition: all 300ms linear 0s;
    color: $white-color;
}

.footer-links-list li:hover a .fa-caret-right {
    left: 6px;
}

.footer-links-list li:hover a {
    text-decoration: none;
}

/* footer ends  */

/* barcode section start  */

.barcode-banner {
    position: relative;
    margin-bottom: 70px;
}

.barcode-banner img {
    position: relative;
    width: 100%;
}

.barcode-banner .banner2-content {
    position: absolute;
    top: 34%;
    left: auto;
    color: $white-color;
    width: 50%;
}

.barcode-banner h1 {
    text-transform: capitalize;
    color: $white-color;
    font-weight: 700;
    font-size: 40px;
}

.barcode-banner h3 {
    font-weight: 600;
    font-size: 24px;
    width: 60%;
    margin-top: 6px;
}

.barcode-list li img {
    width: 20px;
}

.barcode-list li {
    list-style-type: none;
    margin-bottom: 10px;
    color: $white-color;
}

.barcode-pos {
    position: absolute;
    top: 55%;
    left: auto;
    padding: 0 3%;
}

.barcode-img {
    width: 200px;
}

.barcode-list {
    margin-top: 10px;
    padding-left: 50px;
}

.barcode-btn a {
    background: none;
    border: none;
    display: inline-block;
    width: 45%;
}

.barcode-btn {
    padding-left: 50px;
}

/* barcode section ends  */

/* tabs start  */

.home-tabs-list {
    margin: 0 auto;
    border: none;
    width: 100%;
    justify-content: center;
    @media screen and (max-width: 767px){
        justify-content: space-evenly;
    }
    // max-width: 500px;
}

.grey-bg {
    background: #F1F1F1 !important;
}

.home-tabs-list .nav-link {
    border: none;
    font-weight: 700;
    color: #A0A0A0 !important;
    p{
        @media screen and (max-width: 767px){
            font-size: 16px;
        }   
    }
}

.home-tabs-list .nav-link.active {
    color: $dark-black !important;
    background-color: transparent;
}


.home-tabs-list2 .nav-link {
    border: none;
    font-weight: 500;
    font-size: 14px;
    color: $dark-black;
    padding: 0;
}

.home-tabs-list2 .nav-link.active {
    color: $primary-blue !important;
    border-bottom: 2px solid $primary-blue;
    padding-bottom: 5px;
    background-color: transparent;
}

.home-tabs-list2 {
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    margin-bottom: 0;
    white-space: nowrap;
    display: inline-block;
    border: none;
}

.home-tabs-list2 li {
    display: inline-block !important;
    margin-right: 20px !important;
}

.home-tabs-list2 li.nav-item {
    margin-right: 40px;
}

.home-tabs-list2 li.nav-item:last-child {
    margin-right: 0;
}

.tabs-view-btn {
    // position: absolute;
    // right: 6%;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $primary-blue;
    border: 2px solid $primary-blue;
    padding: 6px 12px;
    border-radius: 35px;
    top: 30px;
}

.tabs-view-btn a {
    color: $primary-blue;
}

.tabs-bg {
    position: relative;
}

.tabs-view-btn a {
    text-decoration: none;
}

.item{
    @media screen and (max-width: 480px){
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .product-item {
        max-width: 230px;
        margin-top: 20px;
        @media screen and (max-width: 480px){
            max-width: 153px;
        }
    
        .prod-img {
            border-radius: 5px;
            background: $white-color;
            .product-image {
                width: 230px;
                height: 250px;
                max-height: 260px;
                min-height: 230px;
                padding: 5px;
                @media screen and (max-width: 480px){
                    max-height: 170px;
                    min-height: 170px;
                }
            }
            a{
                .product-discount{
                    p{
                        // top: 0px;
                        // left: 8px;
                        
                        // margin-top: 3px;
                        z-index: 99;
                        width: 40px;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 16px;
                        color: $white-color;
                        translate: 4px 6px;
                        transform: rotate(-44deg);
                    }
                }
            }
        }
        .prod-content {
            padding-top: 10px;
            .prod-name {
                font-weight: 500;
                font-size: 13px;
                line-height: 24px;
                text-align: left;
                color: $black-color;
            
                a {
                    overflow: hidden;
                    line-height: 1.3rem;
                    min-height: 2.7rem;
                    -webkit-box-orient: vertical;
                    display: block;
                    display: -webkit-box;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    @media screen and (max-width: 480px){
                        line-height: 1rem;
                        min-height: 2rem;
                    }
                }
            }
            .prod-rating-sec {
                text-align: left;
                font-size: 13px;
                margin-top: 5px;
                height: 24px;
                @media screen and (max-width: 480px){
                    margin-top: 0px;
                }
                .font14{
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: $black-color;
                }
                .fontw-400 {
                    font-weight: 400 !important;
                    font-size: 13px !important;
                }
            }
        }
    
        .share-list-show{
            position: relative;
            .show-on-hover{
                position: absolute;
                right: 0px;
                &:hover>ul.dropdown-menu {
                    display: block;
                }
            }
            .btn-io {
                border-radius: 5px;
                height: 30px;
                width: 30px;
                padding: 0 !important;
                border: none;
                background: rgba($color: #ffffff, $alpha: .8);
            }
            .dropdown-menu {
                position: absolute;
                top: -56%;
                right: -20px !important;
                z-index: 1000;
                display: none;
                width: 100%;
                font-size: 14px;
                text-align: center;
                list-style: none;
                background-color: rgba(255, 255, 255, 0) !important;
                -webkit-background-clip: padding-box;
                background-clip: padding-box;
                border: none;
                border-radius: 0px;
                -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0) !important;
                box-shadow: 0 6px 12px rgba(0, 0, 0, 0) !important;
                li{
                    display: inline-block;
                }
            }
    
            .dropdown-menu>li>a {
                display: block;
                padding: 0;
                clear: both;
                font-weight: normal;
                white-space: nowrap;
                width: 30px;
                height: 30px;
                border-radius: 50px;
                font-size: 14px;
                line-height: 27px;
                box-shadow: 0px 3px 7px 0px rgba(203, 203, 203, 0.72);
                i{
                    position: relative;
                    top: 2px;
                }
                &.facebook{
                    background-color: #3B5998;
                }
                &.instagram{
                    background-color: #C13584;
                }
                &.twitter{
                    background-color: #33ccff;
                }
                &.linkedin{
                    background-color: #007bb7;
                }
                &.clipboard{
                    background-color: rgba(#000, .5);
                }
            }
            .fa-iox {
                font-size: 22px;
            }
            .fa-iosm {
                margin-top: 7px;
            }
        }
    }
}

.prod-pricing-sec{
    .prod-rating-del {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-decoration-line: line-through;
        color: #838383;
    }
    .prod-price {
        text-align: left;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: $primary-blue;
        @media screen and (max-width: 480px){
            line-height: 20px;
        }
    }
}

.red-txt {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #b53369;
}

.green-txt {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #107100;
}

.rating-color {
    color: #FFD701;
}
.pro-rating-del {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    text-decoration-line: line-through;
    color: #838383;
}

.current-prod-price {
    text-align: left;
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    color: $primary-blue;
    @media screen and (max-width: 480px){
        line-height: 20px;
    }
}

.prod-add-btn {
    .add-to-cart-btn {
        margin-top: 0px;
        border-radius: 8px !important;
        font-size: 16px;
        width: 100%;
        background: transparent;
    }

    .out-of-stock-btn{
        @media screen and (max-width: 480px){
            padding: 6px 16px;
            height: 40px;
        }
        height: 44px;
        margin-top: 0px;
        border-radius: 5px !important;
        font-size: 16px;
        padding: 8px 16px;
        width: 100%;
        color: $white-color;
        font-weight: 600;
        border: none;
    }

    .cart-product-controls {
        margin-top: 0px;
        .btn {
            background-color: $primary-blue;
            color: $white-color;
            font-weight: 500;
            font-size: 18px;
            height: 44px;
            @media screen and (max-width: 767px){
                height: 40px;
                padding-top: 4px !important;
                padding-bottom: 4px !important;
            }
        }
    }
}

.prod-rating-sec span:last-child {
    color: #535353;
}


.fab {
    cursor: pointer;
}

.fab-backdrop {
    color: rgba(255, 255, 255, 0);
}

.fab-primary,
.fab-secondary {
    transition: all 0.35s ease-in-out;
}

.fab.active .fab-primary {
    opacity: 0;
    transform: rotate(225deg);
}

.fab-secondary {
    opacity: 0;
    transform: rotate(-225deg);
}

.fab.active .fab-secondary {
    opacity: 1;
    transform: rotate(0);
    margin-top: -2px;
}

.fa-stack {
    position: relative;
    display: inline-block;
    width: 1.6em !important;
    height: 2em;
    line-height: 1.8em !important;
    vertical-align: middle;
}

.home-tabs-list3 {
    margin: 0 auto;
    border: none;
    width: 100%;
    max-width: 800px;
}

.home-tabs-list3 .nav-link {
    border: none;
    font-weight: 700;
    color: #A0A0A0 !important;
}

.home-tabs-list3 .nav-link.active {
    color: $dark-black !important;
    background-color: transparent;
}

.prod-add-btn .blob-btn__inner {
    background: transparent;
}

.prod-add-btn .blob-btn:hover {
    color: $white-color !important;
}

.tabs-view-btn:hover {
    background: $light-blue-color;
    border-color: $light-blue-color;
}

.tabs-view-btn:hover a {
    color: $white-color;
}

.tabs-icons {
    padding-right: 5px;
    width: 85px;
    height: 85px;
}

/* tabs ends  */

/* why ys start  */

.why-us-box {
    height: 192px;
    border-radius: 10px;
    padding: 10px;
}

.why-icon {
    margin: 15px auto;
    display: block;
    text-align: center;
}

.why-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: $primary-blue;
    text-align: center;
    margin-top: 20px;
}

.why-us-border {
    height: 172px;
    border-radius: 10px;
    border: 2px dashed $primary-blue;
    padding: 10px;
}

/* why us end  */

// SPLASH SCREEN
.splash {
    margin: 0;
    padding: 0;
    height: 65vh;
    width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &.section {
        min-height: 300px;
        height: 100%;
    }

    .logo {
        animation: zoom-in-zoom-out 2s ease infinite;
    }

    @keyframes zoom-in-zoom-out {
        0% {
            transform: scale(1, 1);
        }

        50% {
            transform: scale(1.5, 1.5);
        }

        100% {
            transform: scale(1, 1);
        }
    }
}

// SEARCH AUTO COMPLETE
#ngb-typeahead-0 {
    max-width: 670px;
    overflow: hidden;

    .dropdown-item {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.active {
            background-color: $primary-blue;
        }

        &:hover {
            background-color: $light-blue-color;
        }
    }
}

.pointer {
    cursor: pointer;
}

.bold {
    font-weight: bold;
}

// RESULT

ul {
    list-style-type: none;
}

.filter-wrapper-sec{
    // overflow: scroll;
}

.filter-title-sec {
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
}

.filter-title {
    font-weight: 500;
    font-size: 20px;
    color: var(--black-color);
    margin-right: 10px;
}

.filter-title-sec .fa-filter {
    color: $primary-blue;
    margin-left: 20px;
}

.apply-filter-btn {
    background: $primary-blue;
    border-radius: 8px;
    color: $white-color;
    padding: 6px 10px;
    float: right;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    border: none;
}

.custom-pagination .page-link {
    border: none;
    font-weight: 500;
    font-size: 16px;
    color: #535353;
    width: 30px;
    height: 30px;
    line-height: 16px;
    border-radius: 2px !important;
}

.custom-pagination .page-item.active .page-link {
    background: $primary-blue;
    border-radius: 0;
}

.custom-pagination .page-link:last-child {
    width: auto;
}

.custom-pagination .page-link:focus {
    outline: none;
    box-shadow: none;
}

.products-results .blue-txt {
    font-weight: 500;
}

.sort-by-list li {
    list-style-type: none;
    display: inline-block;
    background: #E5F9FF;
    border-radius: 10px;
    padding: 4px 10px;
    margin-right: 10px;
}

.sort-by-list li span {
    font-weight: 400;
    font-size: 14px;
    color: #535353;
    text-decoration: none;
}

.sort-by-blue-txt {
    padding: 4px 14px;
    color: $primary-blue;
    font-weight: 600;
    font-size: 16px;
}

.sort-by-list {
    padding: 0;
    margin-bottom: 0;
}

.btn-io-results {
    right: 0px !important;
    top: -10px !important;
}

.light-grey-bg {
    background-color: #f8f9fa !important;
}

.filter-show,
.filter-close {
    display: none;
}

.filter-close {
    background: $primary-blue;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 4px;
    font-weight: 400;
    color: $white-color;
    text-align: center;
    margin: 10px;
    position: absolute;
    right: 0;
    top: 0;
}

.filter-show {
    position: fixed;
    background: $dark-black;
    width: 100%;
    color: $white-color;
    z-index: 99;
    padding: 10px;
    border-radius: 5px;
    left: 0;
    bottom: 10px;
    text-align: center;
}

.filter-show .filter-title {
    color: $white-color;
    font-size: 18px;
}

.price-min-range {
    background: #E6F9FF;
    border-radius: 6px;
    padding: 6px 2px;
    font-size: 13px;
    font-weight: 500;
    float: left;
    position: absolute;
    left: -45px !important;
    width: 50px;
    text-align: center;
    top: -8px;
    color: #666666;
    margin-right: 10px;
}

.price-max-range {
    background: #E6F9FF;
    border-radius: 6px;
    padding: 6px 2px;
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    right: -50px;
    width: 50px;
    text-align: center;
    top: -8px;
    left: auto !important;
    color: #666666;
}

.price-range-box {
    position: relative;
}

.sort-by-list li.active {
    background: $primary-blue;
}

.sort-by-list li.active span {
    color: $white-color;
}


.faqs-categories {

    .faq {
        cursor: pointer;
        background-color: $primary-blue;
        color: $white-color;
        border-radius: 10px;
        padding: 45px;
        @media screen and (max-width: 767px){
            padding: 25px;
            height: 100%;
        }

        .faq-title {
            margin-top: 45px;
            @media screen and (max-width: 767px){
                font-size: 20px;
                margin-top: 30px;
            }
        }

        .faq-icon {
            height: 100px;
            @media screen and (max-width: 767px){
                height: 55px;
            }
        }
    }
}

.faq-type {
    .faq-type-bg {
        height: 380px;
        background: url('../images/banner.png'), #D9D9D9;
        background-repeat: no-repeat;
        background-color: $primary-blue;
        background-size: cover;
        background-blend-mode: multiply;
        @media screen and (max-width: 767px){
            height: 190px;
        }
        .faq-icon{
            @media screen and (max-width: 767px){
                height: 65px;
            }
        }
        h2{
            @media screen and (max-width: 767px){
                font-size: 2em;
            }
        }
    }

    ul {
        list-style-type: disc;
    }
}

.accordion {
    .accordion-item {
        color: $dark-black;
        background-color: #EAEAEA;

        .accordion-button {
            background-color: #EAEAEA;
            @media screen and (max-width: 767px){
                font-size: 14px;
                font-weight: 500;
            }

            &:not(.collapsed) {
                background-color: #EAEAEA;
                color: #1E1E1E;
            }

            &:focus {
                box-shadow: none;
            }
        }

        .accordion-body {
            background-color: #F8F8F8;
            @media screen and (max-width: 767px){
                font-weight: 400;
                font-size: 14px;
            }
        }
    }
}

/* DELIVERY ADDRESS PAGE */
.delivery-addresses {
    .address-column {
        background: #F8F8F8;
        border: 0.5px solid #C1C1C1;
        border-radius: 5px;
        height: 250px;

        .new-address-label {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: $primary-blue;
            margin-bottom: 0;
        }

        .address-icon {
            position: absolute;

            img {
                height: 26px;
            }
        }

        .address-text-wrapper {
            .address-label {
                font-size: 14px;
                color: $black-color;
                overflow: hidden;
                line-height: 1.3rem;
                min-height: 6rem;
                -webkit-box-orient: vertical;
                display: block;
                display: -webkit-box;
                overflow: hidden !important;
                text-overflow: ellipsis;
                -webkit-line-clamp: 5;
            }
        }
    }
}

/* ADD DELIVER ADDRESS */
.delivery-address {
    .section-title {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #2E2E2E;
    }

    .nick-name-wrapper {
        .nick-btn {
            background: #F8F8F8;
            border: 1px solid #C1C1C1;
            border-radius: 5px;

            &.active {
                border: 1px solid $primary-blue;
                color: $primary-blue;
                background-color: $white-color;
            }
        }
    }

    .submit {
        min-width: 220px;
    }
}

.form-field {
    .form-label {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 160%;
        color: #4A4A4A;
    }

    .form-control,
    .form-select {
        background-color: #F8F8F8;
        border: 0.5px solid #C1C1C1;
        border-radius: 5px;
        color: #666666;
        font-size: 16px;

        &.max-w {
            max-width: 460px;
        }

        &.max-h {
            height: 49px;
        }

        &:focus {
            box-shadow: none;
        }

        // &.is-valid, &.is-invalid{
        //     background-image: unset;
        //     // &.show-error{
        //     //     background-image: inherit;
        //     // }
        // }
    }
}

// CART
.form-check {
    .form-check-input {
        height: 18px;
        width: 18px;

        &:checked {
            background-color: $primary-blue;
            border-color: $primary-blue;
        }
    }

    .form-check-label {
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
        color: $dark-black;

        span.total {
            color: #44823C;
        }
    }
}

section.cart {
    
    @media screen and (max-width: 480px){
        .txt-black{
            font-size: 20px;
            line-height: 30px;
        }
        .form-check-label{
            font-size: 12px;
        }
    }
}

.cart-delivery-address-wrapper {
    .cart-delivery-address {
        padding: 16px;
        border: 1px solid #B5B5B5;
        border-radius: 5px;

        .home-icon {
            position: absolute;
        }

        .address-text-wrapper {
            margin-left: 30px;

            .address-label {
                font-size: 14px;
                color: $black-color;
                line-height: 1.3rem;
                @media screen and (max-width: 480px){
                    font-size: 12px;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 1;
                    height: 20px;
                    display: -webkit-box;
                    width: 160px;
                }
            }
        }
    }

    .change-address-btn {
        background-color: $primary-blue;
        border-color: $primary-blue;
        width: 195px;
        font-size: 16px;
        line-height: 24px;
        @media screen and (max-width: 480px){
            width: 113px;
            font-weight: 600;
            font-size: 8px;
            line-height: 12px;
        }
    }
}

.cart-info-wrapper {
    .cart-product {
        border: none;
        background-color: $white-color;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
        border-radius: 5px;

        .card-product-image-check {
            top: 5px;
            left: 10px;
            z-index: 9;

            .form-check {
                .form-check-input {
                    height: 15px;
                    width: 15px;
                    border-color: $primary-blue;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }

        .cart-product-image {
            width: 230px;
            height: 250px;
            max-height: 260px;
            min-height: 230px;
            @media screen and (max-width: 767px){
                max-width: 153px;
                min-height: 170px;
                height: 170px;
            }
            // padding: 5px;
        }

        .cart-product-info {
            .cart-product-name {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 21px;
                color: #000;
                overflow: hidden;
                min-height: 2.7rem;
                -webkit-box-orient: vertical;
                display: block;
                display: -webkit-box;
                overflow: hidden !important;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                @media screen and (max-width: 480px){
                    min-height: 2rem;
                    line-height: 1rem;
                    font-size: 10px;
                    margin-top: 15px;
                    margin-bottom: 5px;
                }
            }

            .cart-product-discount-price {
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 19xp;
                text-decoration-line: line-through;
                color: #666666;
                @media screen and (max-width: 480px){
                    font-size: 14px;
                    line-height: 1rem;
                }
            }

            .cart-product-discounted-price {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: $primary-blue;
                @media screen and (max-width: 480px){
                    font-size: 14px;
                    line-height: 1rem;
                }
            }

            .cart-product-discount {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                color: #f32054;
                @media screen and (max-width: 480px){
                    font-size: 12px;
                    line-height: 1rem;
                }
            }

            .send-gift {
                margin-bottom: 12px;
                @media screen and (max-width: 480px){
                    margin-bottom: 5px;
                }

                .form-check-input {
                    height: 16px;
                    width: 16px;
                }

                .form-check-label {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: #5F5F5F;
                    @media screen and (max-width: 480px){
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
            }

            .cart-product-controls {
                .btn {
                    background-color: $primary-blue;
                    color: $white-color;
                    font-weight: 500;
                    font-size: 18px;
                }
            }
        }

        .cross-btn {
            right: 15px;
            height: 32px;
            width: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.light-blue-card {
    background: #E5F9FF;
    border-radius: 5px;
}


.tip-wrapper {
    padding: 20px 15px;

    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #2E2E2E;
    }

    .sub-title {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #666666;
    }

    .tip-valus-wrapper {
        .tip-value {
            background: $white-color;
            padding: 5px 12px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
            margin-bottom: 15px;

            .value {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                color: #666666;
            }

            &.active {
                border-color: $primary-blue;

                .value {
                    color: $primary-blue;
                }
            }
        }
    }
}

.add-coupon-wrapper {
    text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #2E2E2E;
    }

    .fa-angle-right {
        font-size: 22px;
        color: #666666;
    }
}

.free-delivery-info {
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #666666;
    }

    a {
        color: $primary-blue;
    }
}

.cart-bill-info {
    .bill-item {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #666666;
    }

    .bill-item-value {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        color: #1A95BD;
    }

    .total-saving {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #107100;
    }

    .hr {
        width: 100%;
        margin-bottom: 1rem;
        border: 1px solid $white-color;
    }

    .grand-total {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #2E2E2E;
    }

    .total-price {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: right;
        color: $primary-blue;
    }
}

.checkout {
    color: $white-color;
    border-color: $primary-blue;
    background: $primary-blue;
    border-radius: 5px;

    &:hover,
    &:focus-visible,
    &:active {
        color: $white-color;
        border-color: $light-blue-color;
        background: $light-blue-color;
        box-shadow: none;
    }

    &:disabled {
        border-color: $primary-blue;
        color: $light-blue-color;
    }
}

// SIMILAR PRODUCT SECTION
.similar-product {
    background: #FCFCFC;

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #2E2E2E;
    }
}

// ORDER SUMMARY
.order-summary {
    background-color: #FFFFFF;

    .title-wrapper {
        .title {
            font-weight: 600;
            font-size: 32px;
            line-height: 48px;
        }

        .download-summary-link {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $primary-blue;
            text-decoration: none;
        }
    }

    .delivery-info {
        .delivery-info-card {
            background: #FFFFFF;
            box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.05);
            border-radius: 5px;

            .delivery-info-item {
                padding: 18px 14px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #4A4A4A;
                border-bottom: 1px solid #DDDDDD;

                &:last-child {
                    border-bottom: none;
                }

                .txt-blue {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    color: $primary-blue;
                }

                .delivery-status {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #107100;
                }
            }

            .payment-info-item {
                padding: 18px 14px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #4A4A4A;
                border-bottom: 1px solid #DDDDDD;

                &:last-child {
                    border-bottom: none;
                }

                p {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    color: #4A4A4A;
                    margin-bottom: 0;
                }

                p.total {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: $primary-blue;
                }
            }
        }
    }

    .delivery-info,
    .delivery-items-wrapper {
        h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #2E2E2E;
        }
    }

    .delivery-items-wrapper {
        .delivery-item-card {
            background: #FFFFFF;
            box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.05);
            border-radius: 5px;

            .delivery-item {
                padding: 15px;
                border-bottom: 1px solid #DDDDDD;

                &:last-child {
                    border-bottom: none;
                }

                .delivery-item-image {
                    width: 230px;
                    height: 250px;
                    max-height: 260px;
                    min-height: 230px;
                    @media screen and (max-width: 767px){
                        max-width: 153px;
                        min-height: 170px;
                        height: 170px;
                    }
                }

                .delivery-item-info {

                    .delivery-item-name {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 21px;
                        color: #1E1E1E;
                        overflow: hidden;
                        max-width: 701px;
                        min-height: 2.7rem;
                        -webkit-box-orient: vertical;
                        display: block;
                        display: -webkit-box;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;
                        @media screen and (max-width: 480px){
                            min-height: 2rem;
                            line-height: 1rem;
                            font-size: 10px;
                            margin-bottom: 5px;
                        }
                    }

                    .delivery-item-discounted-price {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                        color: $primary-blue;
                        @media screen and (max-width: 480px){
                            font-size: 14px;
                            line-height: 1rem;
                        }
                    }

                    .delivery-item-quantity {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: #1E1E1E;
                        margin: 5px 0px;
                        @media screen and (max-width: 480px){
                            font-size: 12px;
                            line-height: 1rem;
                        }
                    }

                    .delivery-item-rate {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: #1E1E1E;
                        margin-bottom: 0;
                        @media screen and (max-width: 480px){
                            font-size: 12px;
                            line-height: 1rem;
                        }
                    }

                }
            }
        }
    }

    .help-btn {
        min-width: 300px;
    }
}

.write-review-modal {
    .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        text-align: center;
        color: #4A4A4A;
    }

    .form-field {
        .form-label {
            font-weight: 600;
            font-size: 16px !important;
            line-height: 26px;
            color: #4A4A4A;
        }

        .form-control {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: #666666;
        }
    }
}

.img-preview{
    height: 100px;
    width: 100px;
    border: 0.5px solid #C1C1C1;
    border-radius: 5px;
}

.img-box {
    height: 100px;
    width: 100px;
    background-color: #EFEFEF;
    border: 0.5px solid #C1C1C1;
    border-radius: 5px;

    .plus{
        font-size: 48px;
        font-weight: 500;
        color: $primary-blue;
    }

    .add-new{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        bottom: 14px;
    }
}

// ORDER DETAILS

.order-details {
    background-color: #FFFFFF;

    .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 32px;
        color: #2E2E2E;
        @media screen and (max-width: 480px){
            font-weight: 600;
            font-size: 24px;
            line-height: 24px;
        }
    }

    .order-details-tab {
        * {
            border: none;
        }

        .nav-item {
            .nav-link {
                font-weight: 600;
                font-size: 20px;
                line-height: 21px;
                color: #2E2E2E;
                padding: 15px 25px;

                @media screen and (max-width: 480px){
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 14px;
                    padding: 12px 15px;
                }

                &.active {
                    border: none;
                    color: $primary-blue;
                    border-bottom: 2px solid $primary-blue;
                }
            }
        }
    }

    .search-bar-wrapper {
        height: 40px;
        width: 100%;
        border: 1px solid #C7C7C7;
        border-radius: 5px;
        padding: 0px 12px;

        .search-field {
            width: 100%;
            border: none;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #B5B5B5;

            &:focus {
                outline: none;
            }
        }
    }

    .table-responsive {
        .table {
            th {
                padding-bottom: 20px;
                font-weight: 600;
                font-size: 16px;
                line-height: 17px;
                color: #2E2E2E;
                @media screen and (max-width: 480px){
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 12px;
                    padding-bottom: 10px;
                }

                &.active {
                    color: $primary-blue;
                }
            }

            td {
                padding: 25px 15px 25px 0;
                @media screen and (max-width: 480px){
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    padding: 15px 10px 15px 0;
                }

                .order-info {
                    .order-id {
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 16px;
                        color: $primary-blue;
                        margin-bottom: 8px;
                        @media screen and (max-width: 480px){
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 12px;
                        }
                    }

                    .rating-label {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        color: #1E1E1E;
                    }
                }

                .delivery-status {
                    // width: 95px;
                    max-width: max-content;
                    height: 39px;
                    padding: 8px 12px;
                    border-radius: 5px;
                    text-align: center;
                    @media screen and (max-width: 480px){
                        height: 25px;
                        font-size: 8px;
                        line-height: 12px;
                    }

                    .status {
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 22px;
                        @media screen and (max-width: 480px){
                            font-size: 8px;
                            line-height: 12px;
                            font-weight: 400;
                        }
                    }

                    &.refund-initiated{
                        background-color: #FB8315;

                        .status {
                            color: $white-color;
                            margin-bottom: 0;
                        }
                    }

                    &.return-accepted{
                        background-color: #9E4EDD;

                        .status {
                            color: $white-color;
                            margin-bottom: 0;
                        }
                    }
                    &.refund-requested{
                        background-color: #FB8315;

                        .status {
                            color: $white-color;
                            margin-bottom: 0;
                        }
                    }

                    &.return-requested{
                        background-color: #F33434;

                        .status {
                            color: $white-color;
                            margin-bottom: 0;
                        }
                    }

                    &.return-completed{
                        background-color: #126305;

                        .status {
                            color: $white-color;
                            margin-bottom: 0;
                        }
                    }

                    &.return-rejected{
                        background-color: #80807F;

                        .status {
                            color: $white-color;
                            margin-bottom: 0;
                        }
                    }

                    &.delivered {
                        background-color: #09C51B;

                        .status {
                            color: $white-color;
                            margin-bottom: 0;
                        }
                    }

                    &.refunded{
                        background-color: #5EAB65;

                        .status {
                            color: $white-color;
                            margin-bottom: 0;
                        }
                    }

                    &.awaitingpayment {
                        background-color: #FBD50C;

                        .status {
                            color: $dark-black;
                            margin-bottom: 0;
                        }
                    }

                    &.cancelled {
                        background-color: #C91B1B;

                        .status {
                            color: $white-color;
                            margin-bottom: 0;
                        }
                    }

                    &.pending {
                        background-color: #FFD701;

                        .status {
                            color: #000;
                            margin-bottom: 0;
                        }
                    }

                    &.inprogress {
                        background-color: #1A95BD;

                        .status {
                            color: $white-color;
                            margin-bottom: 0;
                        }
                    }
                }

                .table-btn {
                    border: 1px solid $primary-blue;
                    background-color: $primary-blue;
                    color: $white-color;
                    border-radius: 10px !important;

                    @media screen and (max-width: 480px){
                        // width: 52px;
                        border-radius: 5px !important;
                        height: 20px;
                        font-size: 6px;
                    }

                    &:hover,
                    &:active {
                        background-color: $light-blue-color !important;
                        border: 1px solid $light-blue-color !important;
                        color: $white-color !important;
                    }
                }
            }
        }
    }
}

//Profile Page CSS
.profile {
    &--sidebar {
        h4 {
            font-size: 1.8rem;
            font-weight: 600;
        }

        a {
            font-size: 1.3rem;
            font-weight: 600;
            color: #{$dark-black};
            text-decoration: none;
        }

        .user--img {
            &__wrapper {
                position: relative;
                width: 170px;
                height: 140px;
                margin: 0 auto;

                &::before {
                    content: '';
                    width: 157px;
                    height: 84px;
                    border-top-left-radius: 110px;
                    border-top-right-radius: 110px;
                    border: 5px solid #{$primary-blue};
                    border-bottom: 0;
                    position: absolute;
                    top: -15px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }

            &__add {
                position: absolute;
                bottom: 1rem;
                right: 1.5rem;
                background-color: #{$primary-blue};
                padding: 0;
                font-size: 1.4rem;
                border: 0;
                line-height: 1;
                color: #{$white-color};
                border-radius: 50%;
                width: 2rem;
                height: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .accordion {
        .accordion-item {
            border: none;

            #toggle-1-header {
                .accordion-button {
                    background-color: #{$white-color};
                    box-shadow: none;
                    align-items: flex-start;

                    &::after {
                        display: none;
                    }
                }
            }

            .accordion-button {
                gap: 2rem;
                --bs-accordion-btn-active-icon: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAQMAAAC3R49OAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAZQTFRFAAAAGpW9DTAuBwAAAAJ0Uk5TAP9bkSK1AAAAEklEQVR4nGNgIAD+//+AggkAANVbC7kgvzhlAAAAAElFTkSuQmCC');

                &.collapsed {
                    --bs-accordion-btn-icon: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAYAAAARfGZ1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACoSURBVHgB7ZExCsJAEEX/zAbZckshjd7AIwRyAK+gpViINxEkde6hQq5ildpyUZy1X+OOTSDFvvLv/4+FISiUzWUV2BzivN/VW21baIW3kGNgM/CkyhkjkuVZ/j9UNreTgNfJEsIizgLontpA5FhIgCP6Hqu/QnpDQVw+6CDk2s5Z792vgnnNqmDQxrnwc4kE3toHQWF+vlbM3MV5v6/VbT5olk9EPiof36AlkFmHd48AAAAASUVORK5CYII=');
                }

                .accordion-header {
                    &--icon {
                        img {
                            width: 28px;
                            height: 28px;
                            object-fit: scale-down;
                        }
                    }

                    &--heading {
                        h4 {
                            color: #{$primary-blue};
                            ;
                            font-weight: 600;
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .accordion-body {
                background-color: #{$white-color};
                padding-left: 5.2rem;
                padding-bottom: 3rem;
            }
        }
    }

    .account-cards--wrapper {
        .account-card {
            background: #FFFFFF;
            border: 1px solid #BBBBBB;
            border-radius: 12px 15px;
            padding: 10px;

            .title {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 33px;
                color: $primary-blue;
            }

            .sub-title {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                color: #4A4A4A;
            }
        }
    }
}

// Profile Page CSS End
// Contact Page CSS
.contact {
    &--banner {
        background-image: url('../images/contact-banner.png');
        background-position: center;
        background-size: cover;
        color: #{$white-color};

        h1 {
            font-size: 6rem;
            line-height: 1.1;
            font-weight: 500;

            span {
                font-weight: 700;
            }
        }
    }

    &--info {
        &_row {
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
            border-radius: 1rem;
        }

        &__wrapper {
            background-image: url('../images/contact-info-bg.png');
            background-repeat: no-repeat;
            background-position: bottom right;
            background-color: #{$primary-blue};
            color: #{$white-color};
            border-top-left-radius: 1rem;
            border-bottom-left-radius: 1rem;
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);

            h5 {
                font-size: 0.875rem;
            }

            h2 {
                font-weight: 600;
            }

            ul {
                padding: 0;
                margin-top: 4rem;

                li {
                    display: flex;
                    gap: 0.5rem;
                    margin-bottom: 2rem;

                    h6 {
                        font-weight: 600;
                    }

                    p {
                        margin: 0;
                    }

                    a {
                        color: #{$white-color};
                        text-decoration: none;
                    }
                }
            }
        }

        &__form {
            h2 {
                font-size: 2.5rem;
                font-weight: 600;
            }

            .form-control {
                background-color: #F7F7F7;
            }

            &--submit {
                background-color: #{$primary-blue};
                border: 2px solid #{$primary-blue};
                padding: 1rem 2rem;
                font-weight: 600;
                text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

                &:hover {
                    color: #{$primary-blue};
                    background-color: #{$white-color};
                    border-color: #{$primary-blue};
                    text-shadow: none;
                }
            }
        }
    }
}

.about-us {
    .content-wrapper {
        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 38.4px;
            color: #2E2E2E;
        }

        .content {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            text-align: justify;
            color: #2E2E2E;
        }
    }
}

// PRODUCT
$primary-blue: #1a95bd;
$black-color: #2E2E2E;
$white-color: #fff;
$light-blue-color: #6adaff;
$dark-black: #000;

.stock-status {
    &.in {
        background: #ddeff5;
        border-radius: 100px;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        width: max-content;
        color: $primary-blue;
        padding: 5px 10px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    &.out {
        background: #FFC3C3;
        border-radius: 100px;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        width: max-content;
        color: #A70000;
        padding: 5px 10px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
}



.prod-title {
    font-weight: 500;
    font-size: 22px;
    color: $black-color;
    margin-bottom: 10px;
}

.color-option {
    margin-top: 10px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: $black-color;
}

.selected-color {
    color: #F2BC5C;
}

.color-option-list li {
    width: 48px;
    height: 48px;
    border-radius: 100px;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
}

.color-option-list ul {
    padding: 0;
    margin-top: 10px;
}

.color1 {
    background: #FF0000;
}

.color2 {
    background: #F2BC5C;
}

.color3 {
    background: #0000DD;
}

.color4 {
    background: #035E44;
}

.color5 {
    background: #555555;
}

.color6 {
    background: #7724FF;
}

.detail-bluebg-content {
    background: #E5F9FF;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}

.detail-bluebg-content ul {
    padding: 0 14px;
    margin-top: 10px;
    list-style-type: disc;
    margin-bottom: 0;
}

.detail-bluebg-content ul li {
    line-height: 30px;
}

.font16bold {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.details-title {
    margin-top: 10px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: $black-color;
}

.page-num-list ul {
    padding: 0;
}

.page-num-list ul li {
    display: inline-block;
    background: #E6F9FF;
    border-radius: 10px;
    padding: 6px 20px;
    margin: 5px 10px 0 0;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    text-align: center;
}

.page-num-list ul li.active {
    border: 2px solid #1A95BD;
    color: #1A95BD;
}

.delivery-options {
    margin-top: 20px;
}

.delivery-options ul {
    padding: 0;
}

.delivery-options-list li {
    display: inline-block;
    background: #E6F9FF;
    border-radius: 6px;
    padding: 10px;
    width: 30%;
    margin-right: 2%;
    color: #2E2E2E;
    text-align: center;
    font-size: 14px;
}

.delivery-options-list li img {
    display: block;
    margin: 4px auto;
}

.prod-info {
    background: #E6F9FF;
    border-radius: 10px;
    padding: 14px;
    margin-bottom: 20px;
}

.prod-info hr {
    border-color: #fff !important;
}

.prod-info p {
    margin-bottom: 0;
}

.detail-add-buy-btn {
    margin: 26px 0;

    .cart-product-controls {
        .btn {
            background-color: $primary-blue;
            color: $white-color;
            font-weight: 500;
            font-size: 18px;
        }
    }
}

.detail-add-buy-btn .dt-add-btn,
.detail-add-buy-btn .dt-buy-now-btn {
    display: inline-block;
    width: 150px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 10px;
    height: 42px;
}

.detail-add-buy-btn .dt-add-btn:focus,
.detail-add-buy-btn .dt-buy-now-btn:focus {
    outline: none;
    box-shadow: none;
}

.dt-add-btn {
    background: #1A95BD;
}

.dt-buy-now-btn {
    background: #035E44;
}

.color-option-list .active {
    border: 2px solid #1A95BD;
}

.prod-details-page{
    .share-list-show{
        right: 0px;
        .show-on-hover{
            position: absolute;
            right: 0px;
            &:hover>ul.dropdown-menu {
                display: block;
            }
        }
        .btn-io {
            border-radius: 5px;
            height: 48px;
            width: 48px;
            padding: 0 !important;
            border: none;
            background: rgba($color: #ffffff, $alpha: .8);
        }
        .dropdown-menu {
            position: absolute;
            top: 66%;
            right: -20px !important;
            z-index: 1000;
            display: none;
            width: 100%;
            font-size: 14px;
            text-align: center;
            list-style: none;
            background-color: rgba(255, 255, 255, 0) !important;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            border: none;
            border-radius: 0px;
            -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0) !important;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0) !important;
            li{
                display: inline-block;
            }
        }

        .dropdown-menu>li>a {
            display: block;
            padding: 0;
            clear: both;
            font-weight: normal;
            white-space: nowrap;
            width: 30px;
            height: 30px;
            border-radius: 50px;
            font-size: 14px;
            line-height: 27px;
            box-shadow: 0px 3px 7px 0px rgba(203, 203, 203, 0.72);
            i{
                position: relative;
                top: 2px;
            }
            &.facebook{
                background-color: #3B5998;
            }
            &.instagram{
                background-color: #C13584;
            }
            &.twitter{
                background-color: #33ccff;
            }
            &.linkedin{
                background-color: #007bb7;
            }
            &.clipboard{
                background-color: rgba(#000, .5);
            }
        }
        .fa-iox {
            font-size: 22px;
        }
        .fa-iosm {
            margin-top: 7px;
        }
    }
    .color-wrapper{
        padding: 3px;
        &.active{
            border-radius: 50%;
            border: 3px solid $primary-blue;
        }
        .color{
            height: 50px; 
            width: 50px; 
            border-radius: 50%;
        }
    }
    .other-wrapper{
        
        &.active{
            background-color: $primary-blue;
            color: $white-color;
        }
    }
}

.prod-zoom-slider img#show-img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    // max-height: 650px;
}

.prod-zoom-slider .show {
    width: 100%;
    height: 600px;
    // overflow: hidden;
    padding: 10px;
    border-radius: 5px;
    border: 1.5px solid #ECECEC;
    @media screen and (max-width: 480px){
        height: 366px;
    }
}

.prod-zoom-slider .small-img {
    width: 100%;
    height: 70px;
    margin-top: 10px;
    position: relative;
    left: 0;
}

.prod-zoom-slider .small-container {
    width: 100%;
    height: 70px;
}

.prod-zoom-slider .small-container div {
    width: 100%;
    height: 100%;
    position: relative;
}

.prod-zoom-slider .small-container .show-small-img {
    width: 90px;
    height: 100px;
    margin-right: 10px;
    cursor: pointer;
    float: left;
    border: 1.5px solid #1a95bd !important;
    padding: 3px !important;
    border-radius: 5px;
    object-fit: cover;
}

.prod-zoom-slider .small-container .show-small-img:last-of-type {
    margin-right: 0;
}

.prod-zoom-slider #big-img {
    z-index: 99;
}


.solid-btn {
    z-index: 1;
    position: relative;
    outline: none;
    border: none;
    transition: color 0.5s;
    cursor: pointer;
    border-radius: 5px;
}

.solid-btn:before {
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 2px solid $primary-blue;
    border-radius: 5px;
}

.solid-btn:after {
    content: "";
    z-index: -2;
    position: absolute;
    left: 3px;
    top: 3px;
    width: 100%;
    height: 100%;
    transition: all 0.3s 0.2s;
    border-radius: 5px;
}

.solid-btn:hover {
    color: $white-color;
    border-radius: 5px;
}

.solid-btn:hover:after {
    transition: all 0.3s;
    left: 0;
    top: 0;
    border-radius: 5px;
}

.solid_blob-btn__inner {
    z-index: -1;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.solid_blob-btn__blobs {
    position: relative;
    display: block;
    height: 100%;
    filter: url('#goo');
}

.solid_blob-btn__blob {
    position: absolute;
    top: 2px;
    width: 25%;
    height: 100%;
    background: #00bcd4;
    border-radius: 100%;
    transform: translate3d(0, 150%, 0) scale(1.7);
    transition: transform 0.45s;
}

.solid_blob-btn__blob:nth-child(1) {
    left: 0%;
    transition-delay: 0s;
}

.solid_blob-btn__blob:nth-child(2) {
    left: 30%;
    transition-delay: 0.08s;
}

.solid_blob-btn__blob:nth-child(3) {
    left: 60%;
    transition-delay: 0.16s;
}

.solid_blob-btn__blob:nth-child(4) {
    left: 90%;
    transition-delay: 0.24s;
}

.solid-btn:hover .solid_blob-btn__blob {
    transform: translateZ(0) scale(1.7);
}


// HOME CATEGORY SLIDER
.owl-custom-nav{
    .owl-theme{
        .owl-nav{
            overflow: hidden;
            height: 0px;
            width: 100%;
        }
    }

    .nav-btn{
        height: 50px;
        position: absolute;
        width: 25px;
        cursor: pointer;
        z-index: 99;
        // transform: translateY(-50%);
        // box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        background-color: $primary-blue;
        border-radius: 0px;
        top: 0 !important;
    }

    .prev-slide{
        background-image: url(../images/left-chevron.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        left: 0px;
        @media (max-width: 576px) {
            transform: translateY(100%);
        }
        transform: translateY(200%);
    }

    .next-slide{
        background-image: url(../images/right-chevron.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        right: 0px;
        @media (max-width: 576px) {
            transform: translateY(100%);
        }
        transform: translateY(200%);
    }
}

.product-carousel {
    .owl-theme{
        .owl-nav{
            overflow: hidden;
            height: 0px;
            width: 100%;
        }
    }

    .nav-btn{
        height: 50px;
        position: absolute;
        width: 25px;
        cursor: pointer;
        z-index: 99;
        background-color: $primary-blue;
        border-radius: 0px;
        top: 0 !important;
    }

    .prev-slide{
        background-image: url(../images/left-chevron.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        left: 0px;
        transform: translateY(250%);
        @media screen and (max-width: 480px){
            left: -12px;
        }
    }

    .next-slide{
        background-image: url(../images/right-chevron.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        right: 0px;
        @media screen and (max-width: 480px){
            right: -12px;
        }
        transform: translateY(250%);
    }
}

.notification-drawer{
    .title{
        font-size: 1rem;
        color: #555555;
    }
    .date{
        font-size: .7rem;
    }
    .notification-discription{
        color: #555555;
        font-size: .9rem;
    }
    .bell-icon{
        font-size: 48px;
        transform: rotate(-30deg);
    }
    .notification-content{
        .content{
            font-weight: 100;
            font-size: 0.9rem;
            margin-bottom: 3px;
        }
    }
}

.divider{
    position: relative;
    color: #ced4da;
    z-index: 22;
    &::after{
        z-index: -1;
        content: '';
        position: absolute;
        left: 0px;
        top: 12px;
        height: 1px;
        width: 46%;
        background-color: #ced4da;
    }
    &::before{
        z-index: -1;
        content: '';
        position: absolute;
        right: 0px;
        top: 12px;
        height: 1px;
        width: 46%;
        background-color: #ced4da;
    }
}

/* sort dropdown btn */
.sort-dropdown-btn{
    position: relative;
    height: 55px;
    width: 347px;
    padding: 10px 24px;
    text-align: left;
    background-color: #E5F9FF;
    border: 1px solid #1A95BD;
    border-radius: 5px;
    color: #666666;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Poppins';
    &.dropdown-toggle{
        &::after{
            border: none !important;
            background: url('../images/chevron-down.png') no-repeat;
            width: 25px;
            height: 25px;
            position: absolute;
            right: 20px;
            top: 13px;
        }
    }
}

.no-result{
    .text{
        color: #F59B6F;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
        text-align: center;
    }
    .description{
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #535353;
    }
    .home-btn{
        background: $primary-blue;
        color: $white-color;
        width: 325px;
        max-width: 350px;
        height: 50px;
        border-radius: 5px;
        border: none;
    }
}

.location-btn, .location-btn p{
    overflow: hidden;
    line-height: 1rem;
    min-height: 1rem;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    text-align: left;
}

/* order return  */
.return-order{
    h4{
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 15px;
        color: #4A4A4A;
    }
    p{
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: #4A4A4A;
    }
    ul{
        padding: 0;
        margin-bottom: 25px;
        background-color: #F8F8F8;
        border: 0.5px solid #C1C1C1;
        border-radius: 5px;
        li{
            padding: 15px 20px;
            border-bottom: 0.5px solid #C1C1C1;
            &:last-child{
                border-bottom: none;
            }
        }
    }
    .form-check{
        .form-check-label{
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        }
    }
    .text-area{
        background: #F8F8F8;
        border: 0.5px solid #C1C1C1;
        border-radius: 5px;
    }
    .return-btn{
        width: 350px;
        height: 49px;
        background: $primary-blue;
        border-radius: 5px;
        border: none;
        color: $white-color;
    }
}
.view-all{
    border-color: $primary-blue;
    border: 1.5px solid #1A95BD;
    border-radius: 68px;
    color: $primary-blue;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    width: 85px;
    height: 25px;
}

.location-error-message{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #F96060;
}

.notification-btn{
    // padding: 12px 24px;
    background: $primary-blue;
    border-radius: 5px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: $white-color;
    &:hover{
        color: $primary-blue;
        border: 1px solid $primary-blue;
    }
    &.outline{
        border: 1px solid $primary-blue;
        color: $primary-blue;
        background-color: $white-color;
    }
}

.sort-by-dropdown{
    .dropdown-menu{
        min-width: 347px !important;
        border-radius: 0px !important;
        transform: translate3d(0px, 54px, 0px) !important;
        .dropdown-item{
            &.bold{
                font-weight: 600;
            }
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }
    }
}