@media screen and (max-width: 1024px)
{

    .share-list-show-relative #share-list-show .show-on-hover {
    left: 42% !important;
}
    .bannerslider img {
    height: auto;
}

.category-img {
    width: 150px;
    height: 150px;
}

.banner2-content h1 {
    font-size: 24px;
    font-weight: 600;
}

.banner2-content h3 {
    font-size: 18px;
    font-weight: 500;
}
.barcode-img {
    width: 150px;
}

.barcode-list {
    padding-left: 0;
}
.barcode-btn {
    padding-left: 0;
}
.ft-product-img img {
    width: 100%;
}
.categories-list ul li {
    width: 20%;
}
// .prod-zoom-slider .show {
//     height: 300px;
// }
// .prod-zoom-slider .small-container .show-small-img {
//     width: 80px;
//     height: 80px;
// }
}

@media screen and (max-width: 991px)
{

    .space-nav{
        margin-top: 195px;
    }
    
.noti-icon {
    margin-top: 12px;
    span {
        right: 0px;
        left: 10px;
    }
    img {
        width: 22px !important;
    }
}

.banner2-content h1 {
    font-size: 22px;
    font-weight: 600;
}

.banner2-content h3 {
    font-size: 16px;
    font-weight: 500;
}

.home-tabs-list {
    width: 100%;
    margin: 0;
}

.home-tabs-list .nav-link {
    font-weight: 600;
    font-size: 22px;
}

.home-tabs-list2 {
    width: 100%;
    height: 45px;
}

.home-tabs-list3 .nav-link {
    font-size: 22px;
    font-weight: 600;
}

.home-tabs-list3 {
    width: 100%;
    margin: 0;
}

.slider-txt {
    font-size: 22px;
    font-weight: 600;
}

.footer-cls .font28-bold {
    font-size: 22px;
    font-weight: 600;
}

.ft-links-title {
    font-size: 18px;
    font-weight: 500;
}

.footer-links-list li a {
    font-size: 13px;
}

.barcode-img {
    width: 130px;
}

.barcode-list {
    padding-left: 20px;
}

.barcode-btn {
    padding-left: 20px;
}

.barcode-list .font16 {
    font-size: 14px;
}

.barcode-list li {
    margin-bottom: 5px;
}

.ft-product-img img {
    width: 100%;
}

.social-circle li a {
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.social-circle li i {
    line-height: 30px;
    font-size: 14px;
}

ul.social-network li {
    margin: 0;
}
.nav-item.search-box {
    width: auto;
}

.custom-navbar .nav-item {
    margin-right: 5px;
}
.upper-header .pull-right {
    float: none;
    text-align: center;
}
.main-title {
    font-size: 22px;
    font-weight: 500;
    line-height: 14px;
}

.sub-title-width80 {
    width: 100%;
}
.categories-list ul li {
    width: 30%;
}
.why-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #1A95BD;
    text-align: center;
    margin-top: 20px;
}

.filter-show, .filter-close{
    display: block;
}

.filter-close .fa {
    text-align: center;
}
// .filter-wrapper-sec {
//     position: fixed;
//     top: 0;
//     background: #fff;
//     z-index: 99;
//     right: 0;
//     overflow: scroll;
//     height: 100%;
//     padding: 10px;
// }

// .filter-wrapper-sec .card {
//     border: none;
//     display: block;
// }

.filter-wrapper {
    margin-top: 30px;
    display: block;
}
.review-list-sec {
    width: 100%;
    display: block;
}

// .filter-wrapper-sec .card {
//     margin-top: 0 !important;
//     padding: 0 !important;
// }

// .filter-wrapper .p-3 {
//     padding: 10px 20px !important;
// }
// .filter-wrapper-sec{
//     display: none;
// }
/* product details start  */

// .prod-zoom-slider .show {
//     height: 300px;
// }

// .prod-zoom-slider .small-container .show-small-img {
//     width: 60px;
//     height: 60px;
// }
.share-list-show-relative #share-list-show .show-on-hover {
    left: 55% !important;
}
/* product details ends  */

    .profile--sidebar{
        h4{font-size: 1.6rem;}
        a {font-size: 1rem;}
    }
    .contact--info__wrapper{
        background-position: 100% 60%;
        border-bottom-left-radius: 0;
        border-top-right-radius: 1rem;
    }
    .contact--info__wrapper ul{
        margin-top: 3rem;
    }
}


@media screen and (max-width: 767px)
{
    

/* product start */
.share-list-show-relative #share-list-show .show-on-hover {
    left: 80% !important;
}
.prod-title {
    font-size: 16px;
}

.details-title {
    font-size: 14px;
    margin-top: 0;
}

.color-option {
    margin-top: 0;
}

.color-option-list li {
    width: 25px;
    height: 25px;
    margin: 0;
}

.color-option-list ul {
    margin-bottom: 0;
    margin-top: 0;
}

.page-num-list ul li {
    padding: 5px 10px;
    font-size: 14px;
    margin: 0;
}

.delivery-options {
    margin-top: 10px;
}

.delivery-options-list li {
    font-size: 12px;
    padding: 6px;
    margin: 3px;
    width: 40%;
}

.prod-info hr {
    margin-top: 6px;
    margin-bottom: 6px;
}

.detail-add-buy-btn .dt-add-btn, .detail-add-buy-btn .dt-buy-now-btn {
    width: 140px;
}

.detail-bluebg-content ul li {
    line-height: 24px;
}

/* product ends  */

/* result page start    */

    .sort-by-list li {
    margin-bottom: 7px;
    margin-right: 2px;
}

.sort-by-list {
    padding: 10px;
    margin-bottom: 0;
}
/* result page start    */

/* home page start  */
    .why-us-box {
    margin-bottom: 10px;
}

.category-img img {
    width: 150px !important;
    margin: 0 auto;
}

.home-tabs-list3 {
    width: 100%;
    margin-bottom: 10px;
}

.home-tabs-list3 a.nav-link {
    font-size: 16px;
}

.font14 {
    font-size: 13px;
}

.barcode-banner h1 {
    font-size: 16px;
}

    .banner2-content h1 {
    font-size: 13px;
    font-weight: 500;
}

.banner2-content h3 {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
}

.banner2-content {
    left: auto;
    top: 0;
    padding: 10px;
}

.banner2 {margin: 3px;}

.home-tabs-list {
    width: auto;
}

.font28-bold {
    font-size: 16px;
    font-weight: 600 !important;
}

.tabs-view-btn {
    position: relative;
    width: 100px;
    left: auto;
    right: auto;
    text-align: center;
    margin: 0 auto;
    top: -10px;
}

.categories-list .owl-carousel .owl-item {
    margin-top: 0;
}

.home-tabs-list .nav-link {
    padding: 10px 0 0 10px;
}

.home-tabs-list3 .nav-link {
    padding: 0 5px;
}

.barcode-img {
    height: 70px;
    width: 70px;
}

.barcode-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.barcode-pos {
    position: relative;
    top: auto;
    background: #3298ba;
    margin-top: 10px !important;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    left: auto;
    margin: 0;
}

.barcode-banner .banner2-content {
    width: 82%;
    top: 18%;
}

.barcode-list {
    padding: 0;
}

.barcode-list li {
    color: #fff;
}

.barcode-list li img {
    width: 12px;
}

.barcode-list .font16 {
    font-size: 12px;
}

.barcode-list li {
    margin-bottom: 0;
}

.barcode-pos .col-md-8 {width: 100%;}

.barcode-btn button {
    width: 48%;
}

.barcode-btn {
    padding: 0;
}

.barcode-banner {
    margin-bottom: 30px;
}

.footer-cls .font16 {
    font-size: 14px;
}

.footer-cls {
    padding: 30px 0;
}

.news-letter {
    margin-top: 10px;
}

.news-letter-input-box .subs-btn {
    font-size: 14px;
    height: 40px;
}

.news-letter-input-box input {
    font-size: 14px;
    height: 40px;
}

.ft-links-title {
    margin-top: 20px;
    font-size: 16px;
}

.footer-links-list {
    margin-top: 10px;
    margin-bottom: 20px;
}

.footer-links-list li {
    margin-bottom: 0;
}

.footer-links-list li a {
    font-size: 13px;
    font-weight: 400;
}

.copy-right-footer p {
    font-size: 14px;
}

.upper-header .font14 {text-align: center !important;line-height: 26px;font-weight: 500;}

.upper-header .lang-select.font14 select {font-weight: 600;}

.custom-navbar img {
    width: 50px;
}

.bannerslider img {
    object-fit: contain;
    height: auto;
}

.bannerslider .carousel-caption {
    position: relative !important;
    bottom: auto !important;
    right: auto !important;
    left: auto !important;
    top: auto !important;
    z-index: 99999;
}

.slider-txt {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
}

.bannerslider {
    height: auto;
}
.slider-order-btn {
    font-size: 14px;
    margin-top: 10px;
}
.common-pd {
    padding: 30px 0;
}
.white-bg
{
    background: #fff;
}
.location-icon-cls {
    width: 20px !important;
}
.location-icon-cls {
    width: 20px !important;
}

.nav-item.search-box {
    width: 100%;
}

// .login-btn {
//     width: max-content;
//     // margin: 10px 0;
//     padding: 10px 16px !important;
// }

.login-btn img {
    width: 20px;
}

.add-to-cart-btn {
    width: max-content;
    padding: 8px 16px !important;
}

.add-to-cart-btn img {
    width: 20px;
}
.custom-navbar .navbar-collapse {
    border-top: 1px solid #ddd;
    margin-top: 10px;
}
/* home ends  */
/* product details start  */

// .prod-zoom-slider .show {
//     height: 300px;
// }

.prod-zoom-slider .small-container .show-small-img {
    width: 60px;
    height: 60px;
}
/* product details ends  */
    // Profile Page CSS
    .profile .accordion .accordion-item{
        .accordion-button{
            gap: 1rem;
        }
        .accordion-body{
            padding-left: 4.2rem;
        }
    }
    // Profile Page CSS END
    // Contact Page CSS
    .contact--banner h1{
        font-size: 3rem;
    }
    // Contact Page CSS END
}

@media screen and (max-width: 480px){
    .categories-list ul li {
        width: 90%;
    }
    .profile .accordion .accordion-item .accordion-body {
        padding-left: 1.25rem;
    }
}